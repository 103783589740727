import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setSaved, setStarted, setStep } from '../app/store'
import { get_error_message_by_code, filterTypedValue, get_post_object_field_value, get_user_filter } from '../helper'
import axios from 'axios'
import Photogallery from '../fields/Photogallery'
import { toast } from 'react-toastify'
import { saveModel } from '../common'
import { OFFER_URL, MEDIA_URL, price_multiplier } from '../constants'
import useWindowHeight from '../hooks/WindowHeight'
import useWindowSize from '../hooks/Windowsize'
import TableComponent from './TableComponent'
import Miniprofile from './Miniprofile'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const Offerwizard = (props) => {

    const {pimUrl} = props
    const apiModel = 'offer'
    const statusModel = 'offerstatus'
    const imageContainerModel = 'offerimage'
    const imageModel = 'resource'

    const dispatch = useDispatch()

    const user = useSelector((state)=> state.user)

    const initialOfferState = {
        uuid: '',
        user: user.id,
        pre_select_pack: '',
        pack: '',
        request_wine: '',
        request_brand: '',
        year: '',
        is_single_vintage: false,
        format: '',
        quantity: '',
        price: '',
        note: '',
        committed: 1
    }

    const [newOfferData,setNewOfferData] = useState(initialOfferState)
    const [yearDisabled,setYearDisabled] = useState(false)
    const [canSubmitOffer,setCanSubmitOffer] = useState(false)
    const [packsData,setPacksData] = useState({})
    const [formatsData,setFormatsData] = useState({})
    const [triggerReset,setTriggerReset] = useState(0)
    const [offerStates,setOfferStates] = useState({})
    const [offerCounters,setOfferCounters] = useState({
        "pending": 0,
        "published": 0,
        "sold": 0,
        "cancelled": 0
    })
    const [triggerReloadCounters,setTriggerReloadCounters] = useState(1)
    const [additionalFilter,setAdditionalFilter] = useState(get_user_filter(user.id))
    const [statusFilterName,setStatusFilterName] = useState(null)
    const [askConfirmCancel,setAskConfirmCancel] = useState(false)
    const [netPrice,setNetPrice] = useState('-')
    const windowHeight = useWindowHeight()
    const windowSize = useWindowSize()
    const newOffer = useSelector((state)=> state.newOffer)
    //const dataStructure = useSelector((state)=> state.dataStructure)

    const fetchOfferStatusUuid = useCallback(async (statusCode)=>{
        const url = OFFER_URL + statusModel + '/search'
        let config = {
            headers: {'Authorization': 'Bearer ' + user.token},
            params: {}
        },
        requestBody={
            "filter": {
                "func": "and",
                "fields": [
                    {
                        "field": "code",
                        "value": statusCode,
                        "func": "eq"
                    },
                ]
            },
            "format":"ui",
            "inputformat": "ui",
            "offset": 0,
        }
        try{
            const data = await axios.post(url,requestBody,config)
            const result = data.data.result
            if(result){
                let osu = offerStates
                osu[statusCode] = result[0].uuid
                setOfferStates({
                    ...osu
                })
            } else {
                toast.warning('Problema interno: i contatori non sono disponibili')
            }
        } catch(error){
            if(error.response.data.error.code){
                console.log(get_error_message_by_code(error.response.data.error.code))
                toast.error(get_error_message_by_code(error.response.data.error.code))
            } else {
                console.log('Errore nel caricamento dei dati in tabella')
                toast.error('Errore nel caricamento dei dati in tabella')
            }
        }
    //eslint-disable-next-line react-hooks/exhaustive-deps
    },[user.token])


    // Load user's offer counters values
    const loadCounterValue = useCallback(async (offerStatuses,finalStatus)=>{
        // Reset counter first
        let os0 = offerCounters
        os0[finalStatus] = 0
        setOfferCounters({
            ...os0
        })
        const url = OFFER_URL + apiModel + '/search'
        let config = {
            headers: {'Authorization': 'Bearer ' + user.token},
            params: {}
        }
        let tmpCount = 0
        for (let k in offerStatuses){
            let requestBody={
                "filter": {
                    "func": "and",
                    "fields": [
                        {
                            "field": "user",
                            "value": user.id,
                            "func": "eq"
                        },
                        {
                            "field": "offerstatus",
                            "value": offerStatuses[k],
                            "func": "eq"
                        },
                    ]
                },
                "format":"count",
                "inputformat": "ui",
                "offset": 0,
            }
            try{
                const count = await axios.post(url,requestBody,config)
                const numRecords = count.data.result
                if(typeof numRecords === 'number'){
                    tmpCount += numRecords
                } else {
                    toast.warning('Problema interno: i contatori non sono disponibili')
                }
            } catch(error){
                if(error.response.data.error.code){
                    console.log(get_error_message_by_code(error.response.data.error.code))
                    toast.error(get_error_message_by_code(error.response.data.error.code))
                } else {
                    console.log('Errore nel caricamento dei contatori')
                    toast.error('Errore nel caricamento dei contatori')
                }
            }
        }
        let osc = offerCounters
        osc[finalStatus] += tmpCount
        setOfferCounters({
            ...osc
        })
    //eslint-disable-next-line react-hooks/exhaustive-deps
    },[user.token])

    // Populate offerStatuses state with all offerStatuses present in DB
    useEffect(()=>{
        if(user.id){
            if(typeof offerStates.new === 'undefined') fetchOfferStatusUuid('new')
            if(typeof offerStates.unmatched === 'undefined') fetchOfferStatusUuid('unmatched')
            if(typeof offerStates.matched === 'undefined') fetchOfferStatusUuid('matched')
            if(typeof offerStates.tobepublished === 'undefined') fetchOfferStatusUuid('tobepublished')
            if(typeof offerStates.published === 'undefined') fetchOfferStatusUuid('published')
            if(typeof offerStates.tobecancelled === 'undefined') fetchOfferStatusUuid('tobecancelled')
            if(typeof offerStates.cancelled === 'undefined') fetchOfferStatusUuid('cancelled')
            if(typeof offerStates.sold === 'undefined') fetchOfferStatusUuid('sold')
        }
    //eslint-disable-next-line react-hooks/exhaustive-deps
    },[fetchOfferStatusUuid, user.id])

    // Populate counters data remapping original states to its fewer UI equivalent
    useEffect(()=>{
        if(user.id && user.capabilities && user.capabilities.length && triggerReloadCounters){
            if(typeof offerStates.new !== 'undefined'
                && typeof offerStates.unmatched !== 'undefined'
                && typeof offerStates.matched !== 'undefined'
                && typeof offerStates.tobepublished !== 'undefined'
                && typeof offerStates.published !== 'undefined'
                && typeof offerStates.sold !== 'undefined'
                && typeof offerStates.tobecancelled !== 'undefined'
                && typeof offerStates.cancelled !== 'undefined'){
                loadCounterValue([offerStates.new,offerStates.unmatched,offerStates.matched],'pending').then(()=>{
                    loadCounterValue([offerStates.tobepublished,offerStates.published],'published').then(()=>{
                        loadCounterValue([offerStates.sold],'sold').then(()=>{
                            loadCounterValue([offerStates.tobecancelled,offerStates.cancelled],'cancelled')
                        })
                    })
                })
            }
        }
    },[offerStates,user.id,loadCounterValue,user.capabilities,triggerReloadCounters])

    // Load data for wine_format and wine_pack fields
    useEffect(()=>{
        const fetchOfferRelatedObjects = async (fetchModel,sortField) => {
            const url = pimUrl + fetchModel + '/search';
            const config = {
                headers: {'Authorization': 'Bearer ' + user.token},
                params: {}
            }
            const postBody = {
                "format": "ui",
                "inputformat": "ui",
                "sort": {
                    "field": sortField
                }
            }
            try {
                const response = await axios.post(url,postBody,config)
                const recdata = response.data
                if(recdata && recdata.result){
                    return {'result': recdata.result}
                }
                
            } catch(error){
                if(error.response.data.error.code){
                    return {"error": get_error_message_by_code(error.response.data.error.code)}
                } else {
                    return {"error": 'Errore di rete caricando il wizard!'}
                }
            }
        }
        const fetchAllRelatedObjects = async () => {
            if(pimUrl){

                let packs = await fetchOfferRelatedObjects('wine_pack','quantity'),
                    packData = []
                if(packs.result){
                    for(let k in packs.result){
                        packData.push({'uuid': packs.result[k].uuid, 'quantity': packs.result[k].quantity})
                    }
                    setPacksData(packData)
                } else {
                    toast.error(packs.error)
                }

                let formats = await fetchOfferRelatedObjects('wine_format','size'),
                    formatData = []
                if(formats.result){
                    for(let k in formats.result){
                        formatData.push({'uuid': formats.result[k].uuid, 'size': parseInt(formats.result[k].size) !== 'NaN' ? parseInt(formats.result[k].size)/1000 + ' L': ''})
                    }
                    setFormatsData(formatData)
                } else {
                    toast.error(packs.error)
                }

            }
        }
        fetchAllRelatedObjects()
    },[pimUrl,user.token])

    const handleOfferChange = (event,uuidsString,field) => {
        let value = uuidsString ? get_post_object_field_value({type: 'uuid[]'},uuidsString) : filterTypedValue(event.target)
        let dataCopy = newOfferData
        if(event !== null){
            dataCopy[event.target.name] = event.target.name !== 'is_single_vintage' ? value : event.target.checked
            if(event.target.name === 'is_single_vintage'){
                if(dataCopy[event.target.name]){
                    dataCopy['year'] = null
                    setYearDisabled(true)
                } else {
                    setYearDisabled(false)
                }
            }
            if(event.target.name === 'price'){
                if(dataCopy[event.target.name]){
                    let nPrice = (Math.round(dataCopy[event.target.name]/price_multiplier*100)/100).toString()
                    nPrice = nPrice.replace('.',',')
                    let commaIdx = nPrice.indexOf(',')
                        if(commaIdx === -1){
                            nPrice += ',00'
                        } else {
                            let substringAfterComma = nPrice.slice(commaIdx + 1)
                            if(substringAfterComma.length === 1){
                                nPrice += '0'
                            }
                            if(substringAfterComma.length === 0){
                                nPrice += '00'
                            }
                        }
                        nPrice += ' €'
                    setNetPrice(nPrice)
                } else {
                    setNetPrice('-')
                }
            }
        } else{
            dataCopy[field.name] = value
        }
        setNewOfferData({...dataCopy})
    }

    const handleStartOffer = () => {
      dispatch(setStarted(true))
    }

    const cancelNewOffer = (e) => {
      e.preventDefault()
      dispatch(setStep(1))
      dispatch(setStarted(false))
      setYearDisabled(false)
      setNewOfferData(initialOfferState)
      setAskConfirmCancel(false)
      return false
    }

    const checkIfCanSubmitOffer = (newOfferData) => {
        for (let k in newOfferData){
            if(newOfferData[k]){
                if (newOfferData[k] === '' && (k !== 'is_single_vintage') && (k !== 'year')) return false
                if ((newOfferData[k].length < 4) && (k === 'year') && (newOfferData['is_single_vintage']===false)) return false
                if ((newOfferData[k].length < 1) && (k === 'images')) return false
            } else {
                if (k === 'format' || k === 'quantity' || k === 'price'){
                    return false
                }
                if(k === 'year' && !newOfferData['is_single_vintage']){
                    return false
                }
            }
        }
        return true
    }

    useEffect(()=>{
        setCanSubmitOffer(checkIfCanSubmitOffer(newOfferData))
    },[newOfferData])

    useEffect(()=>{
        if(newOfferData.pre_select_pack === 'bottle' && newOfferData.pack === ''){
            let dataCopy = newOfferData
            dataCopy.pack = packsData[0].uuid
            setNewOfferData({...dataCopy})
        }
    },[newOfferData,packsData])

    useEffect(() => {
        const createOffer = async() => {
            const url = OFFER_URL + apiModel + '/create/1';
            const config = {
                headers: {'Authorization': 'Bearer ' + user.token},
                params: {}
            }
            try{
                const response = await axios.get(url,config)
                const recdata = response.data.result[0]
                if(recdata){
                    let dataCopy = newOfferData
                    dataCopy['uuid']=recdata.uuid
                    setNewOfferData({...dataCopy})
                }
            } catch(error){ 
                if(error.response.data.error.code){
                    toast.error(get_error_message_by_code(error.response.data.error.code))
                } else {
                    toast.error('Errore di rete durante la procedura!')
                }
                cancelNewOffer()
            }
        }
        if(newOffer.started && newOffer.step === 1){
            createOffer()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        },[user.token, newOffer]
    )

    const goToStep2 = () => {
        dispatch(setStep(2))
    }

    const handleSave = async (e) => {
        e.preventDefault()
        let result = await saveModel(OFFER_URL,apiModel,newOfferData,user.token)
        if(result.error){
            toast.error(result.error)
        } else {
            dispatch(setStarted(false))
            dispatch(setStep(1))
            dispatch(setSaved(true))
            setNewOfferData(initialOfferState)
            setYearDisabled(false)
        }
    }
    
    const handleTableReset = () => {
        setTriggerReset((triggerReset)=>triggerReset+1)
        setTriggerReloadCounters((triggerReloadCounters)=>triggerReloadCounters + 1)
        applyStatusFilter(null)
    }

    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
      ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
          /* backgroundColor: '#f5f5f9',
          color: 'rgba(0, 0, 0, 0.87)', */
          maxWidth: 220,
          fontSize: theme.typography.pxToRem(15),
          /* border: '1px solid #dadde9', */
        },
      }));
      const HtmlTooltip2 = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
      ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
          backgroundColor: 'lightgray', // '#f5f5f9'
          color: 'rgba(0, 0, 0, 0.87)',
          maxWidth: 500,
          fontSize: theme.typography.pxToRem(15),
          border: '1px solid #dadde9'
        },
      }));

    const applyStatusFilter = (filter) => {
        let myFilter = get_user_filter(user.id)
        if(statusFilterName === filter){
            setStatusFilterName(null)
            setAdditionalFilter(myFilter)
        } else {
            setStatusFilterName(filter)
            myFilter.fields.push({"field": "seller_offerstatus","value": filter,"func": "contains"})
            setAdditionalFilter(myFilter)
        }
    }

    const handleConfirmCancel = (e) => {
        e.preventDefault()
        setAskConfirmCancel(true)
      }
    
      const handleUndoCancel = (e) => {
        e.preventDefault()
        setAskConfirmCancel(false)
      }

  return (
    <>
        <div className='container-fluid font-futura'>

        <div className={"modal custom-modal" + (askConfirmCancel ? "" : " fade")} id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="false">
          <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content bg-dark text-white shadow">
                  <div className="modal-header">
                      <h5 className="modal-title" id="deleteModalLabel">Annullamento inserzione</h5>
                      {/* <button type="button" className="btn mcr-btn btn-with-icon" data-bs-dismiss="modal" title="Chiudi" aria-label="Close" onClick={() => { handleCancelDelete() }}><span className="material-symbols-rounded">close</span></button> */}
                  </div>
                  <div className="modal-body">
                      <p>Vuoi davvero annullare la creazione della tua inserzione?</p>
                  </div>
                  <div className="modal-footer">
                      <button className='btn mcr-btn btn-with-icon ms-0' title="Torna a compilare" onClick={(e) => { handleUndoCancel(e) }}><span className="material-symbols-rounded">undo</span></button>
                      <button className='btn mcr-btn danger btn-with-icon ms-0' title="Elimina" onClick={(e) => { cancelNewOffer(e) }}><span className="material-symbols-rounded">delete</span></button>
                  </div>
              </div>
          </div>
        </div>

        {!newOffer.started && !newOffer.saved && <>
            <div className='row'>
                <div className='col-12 p-3 mt-5 d-flex justify-content-between'>  
                <button className='btn mcr-btn mx-0' style={{width: '157px'}} onClick={handleStartOffer}>
                    <span className="material-symbols-rounded text-even">sell</span>
                    <p className='mb-0'>Nuova inserzione</p>
                </button>

                {windowSize !== 'sm' && windowSize !== 'md' && <>
                    <HtmlTooltip disableFocusListener enterTouchDelay={1} title="Inserzioni in stato venditore: Nuovo" placement="bottom" arrow>
                        <div className={'card p-2 d-flex justify-content-center align-items-center'+(statusFilterName==='nuovo'?' filter-card-button-selected':' filter-card-button')} onClick={()=>{applyStatusFilter('nuovo')}}><span className='counter-text'>In attesa: {offerCounters.pending}</span></div>
                    </HtmlTooltip>
                    <HtmlTooltip disableFocusListener enterTouchDelay={1} title="Inserzioni in stato venditore: Pubblicata" placement="bottom" arrow>
                    <div className={'card p-2 d-flex justify-content-center align-items-center'+(statusFilterName==='pubblicata'?' filter-card-button-selected':' filter-card-button')} onClick={()=>{applyStatusFilter('pubblicata')}}><span className='counter-text'>Pubblicate: {offerCounters.published}</span></div>
                    </HtmlTooltip>
                    <HtmlTooltip disableFocusListener enterTouchDelay={1} title="Inserzioni in stato venditore: Venduta" placement="bottom" arrow>
                        <div className={'card p-2 d-flex justify-content-center align-items-center'+(statusFilterName==='venduta'?' filter-card-button-selected':' filter-card-button')} onClick={()=>{applyStatusFilter('venduta')}}><span className='counter-text'>Vendute: {offerCounters.sold}</span></div>
                    </HtmlTooltip>
                    <HtmlTooltip disableFocusListener enterTouchDelay={1} title="Inserzioni in stato venditore: Annullata" placement="bottom" arrow>
                        <div className={'card p-2 d-flex justify-content-center align-items-center'+(statusFilterName==='annullata'?' filter-card-button-selected':' filter-card-button')} onClick={()=>{applyStatusFilter('annullata')}}><span className='counter-text'>Annullate: {offerCounters.cancelled}</span></div>
                    </HtmlTooltip>
                </>
                }
                
                <button className='btn mcr-btn mx-0' style={{width: '157px'}} onClick={()=>{document.location.href='https://www.hellobacco.com/'}}>
                    <span className="material-symbols-rounded text-even">store</span>
                        <p className='mb-0'>Torna allo shop</p>
                </button>
                </div>
            </div>
            {(windowSize === 'sm' || windowSize === 'md') &&
                <div className='row'>
                    <div className={'col-6 col-md-3 d-flex mb-2'+(windowSize === 'md'?'  justify-content-start':'  justify-content-start')}>
                        <HtmlTooltip disableFocusListener enterTouchDelay={1} title="Inserzioni in status: Nuova, Non abbinata, Abbinata" placement="bottom" arrow>
                            <div className={'card p-2 d-flex justify-content-center align-items-center'+(statusFilterName==='nuovo'?' filter-card-button-selected':' filter-card-button')} onClick={()=>{applyStatusFilter('nuovo')}}><span className='counter-text'>In attesa: {offerCounters.pending}</span></div>
                        </HtmlTooltip>
                    </div>
                    <div className={'col-6 col-md-3 d-flex mb-2'+(windowSize === 'md'?'  justify-content-center':'  justify-content-end')}>
                        <HtmlTooltip disableFocusListener enterTouchDelay={1} title="Inserzioni in status: Da pubblicare, Pubblicata" placement="bottom" arrow>
                            <div className={'card p-2 d-flex justify-content-center align-items-center'+(statusFilterName==='pubblicata'?' filter-card-button-selected':' filter-card-button')} onClick={()=>{applyStatusFilter('pubblicata')}}><span className='counter-text'>Pubblicate: {offerCounters.published}</span></div>
                        </HtmlTooltip>
                    </div>
                    <div className={'col-6 col-md-3 d-flex mb-2'+(windowSize === 'md'?'  justify-content-center':'  justify-content-start')}>
                        <HtmlTooltip disableFocusListener enterTouchDelay={1} title="Inserzioni in status: Venduta" placement="bottom" arrow>
                            <div className={'card p-2 d-flex justify-content-center align-items-center'+(statusFilterName==='venduta'?' filter-card-button-selected':' filter-card-button')} onClick={()=>{applyStatusFilter('venduta')}}><span className='counter-text'>Vendute: {offerCounters.sold}</span></div>
                        </HtmlTooltip>
                    </div>
                    <div className={'col-6 col-md-3 d-flex mb-2'+(windowSize === 'md'?'  justify-content-center':'  justify-content-end')}>
                        <HtmlTooltip disableFocusListener enterTouchDelay={1} title="Inserzioni in status: Da annullare, Annullata" placement="bottom" arrow>
                            <div className={'card p-2 d-flex justify-content-center align-items-center'+(statusFilterName==='annullata'?' filter-card-button-selected':' filter-card-button')} onClick={()=>{applyStatusFilter('annullata')}}><span className='counter-text'>Annullate: {offerCounters.cancelled}</span></div>
                        </HtmlTooltip>
                    </div>
                </div>
            }
            <div className="container-fluid" style={{height: windowHeight - 300 + 'px'}}>
                <div className='row mb-2'><div className='col-12 d-flex align-align-items-baseline justify-content-between'>
                <h3 className='h3 d-inline-block me-3 mb-0 mt-1' style={{fontFamily: 'Futura Bold'}}>Le mie inserzioni</h3>
                <div className='d-inline'>
                <HtmlTooltip2 disableFocusListener enterTouchDelay={1} title="Nella tabella puoi vedere tutte le tue inserzioni.
                Cliccando al centro dell'intestazione di una colonna, puoi ordinare le inserzioni secondo quella colonna, in ordine crescente o descrescente.
                Apparirà, in questo caso, una freccia che indicherà l'ordinamento applicato.
                Cliccando sull'icona con le 3 linee che compare nella parte destra delle intestazioni delle colonne, quando si passa sopra con il cursore, è possibile applicare dei filtri sulla relativa colonna.
                Cliccando sui pulsanti rossi in alto che indicano le statistiche relative alle inserzioni, puoi filtrare le stesse in base allo stato indicato.
                Un secondo click sul pulsante rosso evidenziato rimuoverà il filtro.
                Tramite il pulsante giallo qui a destra, è possibile eliminare tutti i filtri e gli ordinamenti eventualmente presenti sulla tabella.
                Cliccando invece sul tasto in alto a destra 'Nuova inserzione' verrai portato/a al wizard di creazione di una nuova inserzione.
                Se vuoi tornare alla lista prodotti in vendita su Hellobacco, clicca su 'Torna allo shop'.
                Se si vuoi effettuare il logout dal tuo account venditore, clicca sull'icona della porta in alto, nella parte bianca dell'header." placement="bottom" arrow>
                    <button className='btn mcr-btn success btn-with-icon' onClick={()=>{return false}}>
                        <span className="material-symbols-rounded">question_mark</span></button>
                </HtmlTooltip2>
                <button className='btn mcr-btn warning btn-with-icon' title='Reset filtri' onClick={()=>{handleTableReset()}}>
                <span className="material-symbols-rounded">filter_alt_off</span></button>
                </div>
            </div></div>
          {<TableComponent apiModel={apiModel} baseUrl={OFFER_URL} triggerReset={triggerReset} additionalFilter={additionalFilter} disableRowClick={true} />}
        </div>
        </>}

        {newOffer.started && !newOffer.saved && <div className=''>

            {(newOffer.step === 1 && newOfferData.uuid) && <div className='col-12 col-md-8 offset-md-2 col-lg-5 m-lg-auto p-2'>
            <div style={{marginTop: '90px'}}>
                <form className='wl-form rounded shadow p-2'>
                <h5 className='h4'>Cosa vuoi vendere?</h5>
                <div className="form-check mt-4">
                    <input className="form-check-input custom-control-input" type="radio" name="pre_select_pack" value="box" onChange={(e)=>{handleOfferChange(e)}} />
                    <label className="form-check-label" htmlFor="pre_select_pack">Casse</label>
                </div>
                <div className="form-check mb-4">
                    <input className="form-check-input custom-control-input" type="radio" name="pre_select_pack" value="bottle" onChange={(e)=>{handleOfferChange(e)}} />
                    <label className="form-check-label" htmlFor="pre_select_pack">Bottiglie</label>
                </div>
                <div className='form-footer'>
                <button className='btn mcr-btn danger border-even btn-with-icon ms-0' title="Annulla" onClick={(e) => {handleConfirmCancel(e)}} ><span className="material-symbols-rounded">close</span></button>
                <button className='btn mcr-btn border-even btn-with-icon ms-0 float-end' title="Prossimo" onClick={() => {goToStep2()}} disabled={!newOfferData.pre_select_pack} ><span className="material-symbols-rounded">navigate_next</span></button>
                </div>

                </form>
            </div>
            </div>}

            {(newOffer.step === 2) && <div className='col-12 col-md-8 offset-md-2 col-lg-5 m-lg-auto p-2'>
            <div className='mt-5'>
                <form className='wl-form rounded shadow p-2'>
                <h5 className='h4'>Dati sul vino:</h5>

                <div className='row mt-3 mb-2'>
                    <div className='col-4 d-flex align-items-center'>
                    <label className="form-label" htmlFor="request_wine">Vino:</label>
                    </div>
                    <div className='col-8'>
                    <input type='text' value={newOfferData.request_wine} className='form-control' name='request_wine' placeholder='Nome del vino...' onChange={(e)=>{handleOfferChange(e)}} />
                    </div>
                </div>

                <div className='row mt-3 mb-2'>
                    <div className='col-4 d-flex align-items-center'>
                    <label className="form-label" htmlFor="request_brand">Produttore:</label>
                    </div>
                    <div className='col-8'>
                    <input type='text' value={newOfferData.request_brand} className='form-control' name='request_brand' placeholder='Produttore...' onChange={(e)=>{handleOfferChange(e)}} />
                    </div>
                </div>

                <div className='row mt-3 mb-2'>
                    <div className='col-4 d-flex align-items-center'>
                    <label className="form-label" htmlFor="is_single_vintage">Vino senza annata:</label>
                    </div>
                    <div className='col-8 d-flex align-items-center'>
                    <input type='checkbox' value={newOfferData.is_single_vintage} className='mcr-checkbox-wine' name='is_single_vintage' onChange={(e)=>{handleOfferChange(e)}} />
                    </div>
                </div>

                <div className='row mt-3 mb-3'>
                    <div className='col-4 d-flex align-items-center'>
                    <label className="form-label" htmlFor="year">Annata:</label>
                    </div>
                    <div className='col-8'>
                    <input type='number' disabled={yearDisabled} value={newOfferData.year} minLength={4} maxLength={4} min={1945} max={new Date().getFullYear()} className={'form-control' + (newOfferData.year ? ' text-end' : '')} name='year' placeholder='Annata...' onChange={(e)=>{handleOfferChange(e)}} />
                    </div>
                </div>

                </form>
            </div>

            <div className='mt-4'>
                <form className='wl-form rounded shadow p-2'>
                <h5 className='h4'>Dati sull'offerta:</h5>

                <div className='row mt-3 mb-2'>
                    <div className='col-4 d-flex align-items-center'>
                    <label className="form-label" htmlFor="format">Formato:</label>
                    </div>
                    <div className='col-8'>
                    <select value={newOfferData.format} className='form-select' name='format' onChange={(e)=>{handleOfferChange(e)}} >
                        <option value=''>Sel. formato</option>
                        {formatsData.map((singleFormat) => {
                            return <option key={singleFormat.uuid} value={singleFormat.uuid}>{singleFormat.size}</option>
                        })}
                    </select>
                    </div>
                </div>

                {newOfferData.pre_select_pack === 'box' && <div className='row mt-3 mb-2'>
                    <div className='col-4 d-flex align-items-center'>
                    <label className="form-label" htmlFor="pack">Casse da:</label>
                    </div>
                    <div className='col-8'>
                    <select value={newOfferData.pack} className='form-select' name='pack' onChange={(e)=>{handleOfferChange(e)}} >
                        <option value=''>Sel. qta/cassa:</option>
                        {packsData.map((singlePack) => {
                            return <option key={singlePack.uuid} value={singlePack.uuid}>{singlePack.quantity}</option>
                        })}
                    </select>
                    </div>
                </div>}

                <div className='row mt-3 mb-2'>
                    <div className='col-4 d-flex align-items-center'>
                    <label className="form-label" htmlFor="quantity">Quantità:</label>
                    </div>
                    <div className='col-8'>
                    <input type='number' value={newOfferData.quantity} min={1} className={'form-control' + (newOfferData.quantity ? ' text-end' : '')} name='quantity' placeholder='Quantità...' onChange={(e)=>{handleOfferChange(e)}} />
                    </div>
                </div>

                <div className='row mt-3 mb-2'>
                    <div className='col-4 d-flex align-items-center'>
                    <label className="form-label" htmlFor="price">Prezzo:</label>
                    </div>
                    <div className='col-8'>
                    <input type='number' value={newOfferData.price} min={1} className={'form-control' + (newOfferData.price ? ' text-end' : '')} name='price' placeholder='Prezzo...' onChange={(e)=>{handleOfferChange(e)}} />
                    </div>
                </div>

                <div className='row mt-3 mb-2'>
                    <div className='col-12 d-flex align-items-center'>
                        <label className="form-label">Importo che riceverai in caso di vendita: {netPrice}</label>
                    </div>
                </div>

                <div className='row mt-3 mb-2'>
                    <div className='col-12'>
                        <Photogallery
                            field={null}
                            height={120}
                            imageModel={imageModel}
                            imageContainerModel={imageContainerModel}
                            parentModel={apiModel}
                            parentModelUuid={newOfferData.uuid}
                            baseUrl={OFFER_URL}
                            mediaUrl={MEDIA_URL}
                            makeDisable={false}
                            changeHandler={handleOfferChange}
                        />
                    </div>
                </div>

                <div className='row mt-3 mb-2'>
                    <div className='col-4 d-flex align-items-center'>
                    <label className="form-label" htmlFor="note">Note:</label>
                    </div>
                    <div className='col-8'>
                    <textarea type='text' value={newOfferData.note} className='form-control' name='note' placeholder='Le tue note...' onChange={(e)=>{handleOfferChange(e)}} />
                    </div>
                </div>

                <div className='form-footer'>
                <button className='btn mcr-btn border-even danger btn-with-icon ms-0' title="Annulla" onClick={(e) => {handleConfirmCancel(e)}} ><span className="material-symbols-rounded">close</span></button>
                <button className='btn mcr-btn border-even success btn-with-icon ms-0 float-end' title="Fine" onClick={(e) => {handleSave(e);return false}} disabled={!canSubmitOffer} ><span className="material-symbols-rounded">done</span></button>
                </div>

                </form>
            </div>


            </div>}

        </div>}

        {newOffer.saved && !newOffer.started && <><Miniprofile setTriggerReloadCounters={setTriggerReloadCounters} HtmlTooltip={HtmlTooltip2} /></>}
        
        </div>
    </>
  )
}

export default Offerwizard
